.info_container {
  li {
    width: 32%;
  }
}

.contact_box {
  // .main_title {
  //   line-height: 1.455;
  //   color: $white;
  //   @include rtl-sass-value(text-align, left, right);
  //   margin-bottom: 1.5rem;
  // }

  ul {
    li {
      margin: 0 15px 30px;
      color: $primary-alt;
      background-color: transparent;
      border: 1px solid $primary-alt;
      border-radius: 60px;
      display: inline-block;
      padding: 12px 20px;

      a {
        color: $primary-alt;
      }

      img {
        @include rtl-sass-prop(margin-right, margin-left, 20px);
      }
    }
  }
}

.contact_btn {
  display: grid;
  grid-template-columns: repeat(3, map-get($sizes, auto));
  column-gap: 15px;
  margin-top: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, map-get($sizes, 100));
    row-gap: 15px;
  }

  li {
    height: 100px;
    background: $theme-hue;
    border-radius: 3px;
    cursor: pointer;
    display: flex;

    .con_left {
      width: 100px;
      height: 100px;
      background: $black2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      img {
        width: 56px;
        height: 56px;
      }
    }

    .con_right {
      flex: 1;
      box-sizing: border-box;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      word-break: break-all;
      font-weight: 300;

      >p {
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 550px) {
  .contact_box ul li {
    width: 100%;
    max-width: 200px;

    img {
      @include rtl-sass-prop(margin-right, margin-left, 10px);
    }
  }
}